.submittable-input_root {
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  height: 23%;
  width: calc(var(--vh, 1vh) * 37);
  display: flex;
  position: relative;
  background-image: url('../assets/inputWithSubmit.png');
  background-position: center;
}

.submittable-input_input {
  background: transparent;
  width: 70%;
  border: 0;
  height: 50%;
  font-size: 60%;
  outline: none;
}

.submittable-input_warning {
  position: absolute;
  bottom: -10%;
  color: red;
}

.submittable-input_submit {
  width: 32%;
  height: 100%;
  position: absolute;
  display: flex;
  right: 0;
  justify-content: center;
  align-items: center;
  /*background-image: url("../assets/inputSubmit.png");*/
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
