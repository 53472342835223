.yard-and-display-screen_bg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 40%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: yard-and-display_bg-enter 0.3s;
}

.yard-and-display_body {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: calc(var(--vh, 1vh) * 15);
}

@keyframes yard-and-display_bg-enter {
  0% {
    transform: translateY(calc(var(--vh, 1vh) * -100));
  }
  100% {
    transform: translateY(0);
  }
}
.yard-and-display-screen_profile-part-root {
  font-size: calc(var(--vh, 1vh) * 4);
}
.yard-and-display-screen_avatar-container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.yard-and-display-screen_avatar-ring {
  width: calc(var(--vh, 1vh) * 27);
  height: calc(var(--vh, 1vh) * 27);
  background-image: url('./assets/avatarFrame.png');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: -1vh;
  left: -1vh;
}

.yard-and-display-screen_avatar {
  width: calc(var(--vh, 1vh) * 25);
  height: calc(var(--vh, 1vh) * 25);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: #333333;
  border-radius: 50%;
  position: relative;
}

.yard-and-display-screen_camera-container {
  width: calc(var(--vh, 1vh) * 9);
  height: calc(var(--vh, 1vh) * 9);
  position: absolute;
  bottom: calc(var(--vh, 1vh) * -1);
  right: calc(var(--vh, 1vh) * -1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.yard-and-display-screen_camera {
  width: calc(var(--vh, 1vh) * 3.5);
  height: calc(var(--vh, 1vh) * 3.2);
  background-size: contain;
  background-repeat: no-repeat;
}

.yard-and-display-screen_name {
  color: white;
  overflow: hidden;
  margin-top: calc(var(--vh, 1vh) * 5);
  border-radius: 4px;
  width: 160%;
  height: calc(var(--vh, 1vh) * 7);
  position: relative;
  display: flex;
  align-items: center;
  background-position: center;
  background-image: url('./assets/input.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.yard-and-display-screen_invite {
  position: absolute;
  bottom: calc(var(--vh, 1vh) * 20);
  width: 80vw;
  height: calc(var(--vh, 1vh) * 10);
}

.yard-and-display-screen_game {
  position: absolute;
  bottom: calc(var(--vh, 1vh) * 5);
  width: 80vw;
  height: calc(var(--vh, 1vh) * 10);
}

.yard-and-display-popup_body {
  z-index: 1;
  width: calc(var(--vh, 1vh) * 29);
  height: calc(var(--vh, 1vh) * 25);
  background-image: url('./assets/highFive.png');
  background-repeat: no-repeat;
  background-size: contain;
}
