.game_button-root {
  position: relative;
  height: 100%;
  display: flex;
  width: 95%;
  justify-content: stretch;
  align-items: center;
  margin-left: 2.5%;
  margin-right: 2.5%;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;
}

.game_button-btn {
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: calc(var(--vh, 1vh) * 4);
  animation: game-button_appear 0.3s;
  transition: filter 0.3s, transform 0.3s;
  text-align: center;
  padding: calc(var(--vh, 1vh) * 2);
  box-sizing: border-box;
  background-image: url('../assets/btn.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.game_button-btn:active {
  filter: brightness(110%);
  transform: scale(0.9);
}

.game_button-btn_primary {
}

.game_button-btn_secondary {
  background-image: url('../assets/btnSeconadary.png');
}

.game_button-disabled {
  filter: grayscale(0.8);
}

@keyframes game-button_appear {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
