.take-pic-dialog-root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.take-pic-dialog-image-wrapper {
  display: flex;
  flex-direction: column;
}

.take-pic-dialog-image {
  border-radius: 50%;
}

.take-pic-dialog-bg {
  background-image: url('./assets/avatarFrame.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 340px;
  height: 340px;
  position: absolute;
  margin-top: -33%;
}

.take-pic-dialog-close-icon-button {
  align-self: flex-start;
}
